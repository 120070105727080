import TableComponent from "@components/TableComponent";
import { TableCell, Typography } from "@material-ui/core";
import { InAppReceiptResponse } from "@reshopper/admin-client";
import { toDanishDateFormat } from "@utils/miscellaneous";


export default function UserInAppReceiptComponent(props: { userId: string }) {
  return (
    <>
      <Typography variant="h5">InApp Receipts</Typography>
      <TableComponent<InAppReceiptResponse>
        url={`/admin/users/${props.userId}/in-app-receipts`}
        isSelectable={false}
        headers={[
          { databaseSortingKey: x => x.createdUtc, label: "Date" },
          { databaseSortingKey: x => x.purchasedUtc, label: "Purchase Date" },
          { databaseSortingKey: x => x.platform, label: "Platform" },
          { databaseSortingKey: x => x.productSku, label: "ProductSku" },
          { databaseSortingKey: x => x.quantity, label: "Quantity" },
          { databaseSortingKey: x => x.originalTransactionsId, label: "Transaction ID" },
        ]}
        renderRow={inAppReceipt =>
          <>
            <TableCell>{toDanishDateFormat(inAppReceipt.createdUtc)}</TableCell>
            <TableCell>{toDanishDateFormat(inAppReceipt.purchasedUtc)}</TableCell>
            <TableCell>{inAppReceipt.platform}</TableCell>
            <TableCell>{inAppReceipt.productSku}</TableCell>
            <TableCell>{inAppReceipt.quantity}</TableCell>
            <TableCell>{inAppReceipt.originalTransactionsId}</TableCell>
          </>
        } />
    </>
  );
}
