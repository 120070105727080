import UserDailySessionStatsComponent from "@components/admin/users/details/UserDailySessionStatsComponent";
import UserInAppReceiptComponent from "@components/admin/users/details/UserInAppReceiptComponent";
import UserProfileComponent from "@components/admin/users/details/UserProfileComponent";
import UserSubscriptionTableComponent from "@components/admin/users/details/UserSubscriptionTableComponent";
import { Box, Button } from "@material-ui/core";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { AdminUserResponse } from "@reshopper/admin-client";

export default function UserDetailsComponent(props: {
  user: AdminUserResponse;
}) {
  const padding = 2;

  return (
    <>
      <Box>
        <Button
          variant="contained"
          fullWidth
          color="primary"
          size="large"
          style={{marginBottom: 20}}
          href={`https://admin.reshopper.com/users/${props.user.id}`}
          target={"_blank"}
        >
          Se brugeren i det nye admin <OpenInNewIcon style={{marginLeft: 10}}/>
        </Button>
      </Box>
      <Box pb={padding}>
        <UserProfileComponent userId={props.user.id} />
      </Box>

      <Box py={padding}>
        <UserSubscriptionTableComponent userId={props.user.id} />
      </Box>
      <Box py={padding}>
        <UserInAppReceiptComponent userId={props.user.id} />
      </Box>
      <Box py={padding}>
        <UserDailySessionStatsComponent userId={props.user.id} />
      </Box>
    </>
  );
}
