import { GraphPoint } from "@components/admin/stats/StatsHelper";
import { Box, Typography } from "@material-ui/core";
import { BarLegendProps, ResponsiveBar } from "@nivo/bar";
import { Theme } from "@nivo/core";
import { formatNumber } from "@utils/formatting";
import { toDanishDateFormat } from "@utils/miscellaneous";

export default function StackBarChartComponent(props: {
	data: GraphPoint[],
	displayTicks: boolean
	labelFontSize?: number,
	tickFontSize?: number,
	marginTop?: number,
	marginBottom?: number,
	marginLeft?: number,
	barBackgroundColors: any[],
	textColor?: string,
	backgroundColor?: string,
	legends?: BarLegendProps[] | undefined,
	xAxisTickFormat?: string
}): JSX.Element {
	const theme: Theme = {
		background: !props.backgroundColor ? "#ffffff" : props.backgroundColor,
		textColor: !props.textColor ? "#000000" : props.textColor,
		labels: {
			text: { fontSize: props.labelFontSize ?? 14 }
		},
		axis: {
			ticks: {
				text: {
					fontSize: props.displayTicks ? (props.tickFontSize ?? 14) : 0
				}
			}
		},
		legends: {
			text: {
				fontSize: 22
			}
		}
	};

	const mappedData = props.data.map(graphPoint => {
		if (typeof graphPoint.x !== "number" && typeof graphPoint.x !== "string") {
			return {
				x: graphPoint.x as Date,
				y1: +graphPoint.y1,
				y2: +graphPoint.y2
			} as any;
		} else {
			return {
				x: graphPoint.x,
				y1: graphPoint.y1,
				y2: graphPoint.y2
			} as any;
		}
	});

	const generateResponsiveBarKeys = () => {
		if (props.data.length === 0 || !props.data[0].y2) {
			return  ["y1"];
		}
		return ["y1", "y2"];
	}

	const generateResponsiveBarColors = () => {
		if (!props.barBackgroundColors) {
			return { scheme: 'category10' };
		}
		if(!props.barBackgroundColors[1]){
			return props.barBackgroundColors[0];
		}
		return props.barBackgroundColors;
	}

	

	return <>
		<ResponsiveBar
			data={mappedData}
			groupMode="grouped"
			keys={generateResponsiveBarKeys()}
			indexBy="x"
			labelSkipHeight={26}
			valueFormat={value => `${Number(value).toLocaleString('da-DK')}`}
			margin={{ top: props.marginTop ?? 20, right: 32, bottom: props.marginBottom ?? 80, left: props.marginLeft ?? 32 }}
			colors={generateResponsiveBarColors()}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				format: (value) => { return value instanceof Date ? toDanishDateFormat(value as Date, props.xAxisTickFormat ?? "ccc dd/MM") : value}}}
			axisLeft={{
				tickSize: 0,
				tickPadding: 5,
				tickRotation : 0
			}}
			labelTextColor="#ffffff"
			theme={theme}
			tooltip={({ label, value }) => (
				<Box style={{
					borderWidth: 1,
					borderRadius: 5,
					backgroundColor: "white",
					padding: 12,
					paddingTop: 8,
					paddingBottom: 8,
					boxShadow: '0px 0px 10px rgba(0,0,0,0.15)'
				}}>
					<Typography
						variant="subtitle2"
						align="left"
						style={{ fontWeight: "lighter" }}
					>
						{label.substr(4)}
					</Typography>
					<Typography
						variant="body1"
						align="center"
						style={{ fontWeight: "bolder" }}>
						{formatNumber(value)}
					</Typography>
				</Box>
			)}
			legends={props.legends}
		/>
	</>
}

