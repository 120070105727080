import { adminClient } from "@api/admin/AdminClient";
import { queryApi } from "@hooks/api";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Skeleton from "@material-ui/lab/Skeleton";
import { toDanishDateFormat } from "@utils/miscellaneous";
import { isAfter } from "date-fns";

interface Props {
  userId: string
}

export default function UserSubscriptionTableComponent(props: Props) {
  const [subscription] = queryApi(
    async (options: any) => 
      await adminClient().adminUserSubscriptionGet(props.userId, options), 
    []);

  function evaluateActive(expiresUtc: Date) {
    return isAfter(expiresUtc, new Date()) ?
      <CheckCircleIcon color="secondary" /> :
      <RemoveCircleIcon color="error" />;
  }

  function evaluateAutoRenew(autoRenew: boolean) {
    return autoRenew ? "Yes" : "No";
  }

  function InsertSkeleton(): JSX.Element {
    let rowHeight = 45;

    return <TableRow>
      <Skeleton
        height={rowHeight}
        component="td" />
    </TableRow>
  }

  function renderSubscriptionRow() {
    if(!subscription)
      return null;

    return <>
      <TableRow>
        <TableCell>{evaluateActive(subscription.expiresUtc)}</TableCell>
        <TableCell>{toDanishDateFormat(subscription.updatedUtc)}</TableCell>
        <TableCell>{toDanishDateFormat(subscription.expiresUtc)}</TableCell>
        <TableCell>{(subscription.details !== undefined) ? subscription.details.type : "No Type"}</TableCell>
        <TableCell>{(subscription.details !== undefined) ? subscription.details.transactionId : "No Transaction ID"}</TableCell>
        <TableCell>{subscription.productSku}</TableCell>
        <TableCell>{evaluateAutoRenew(subscription.autoRenew)}</TableCell>
      </TableRow>
    </>
  }

  if (subscription === null) {
    return <>
      <Typography variant="h5">Subscription</Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <caption>No entities found.</caption>
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell>Expires</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Product SKU</TableCell>
              <TableCell>Auto Renew</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          </TableBody>
        </Table>
      </TableContainer>
    </>;
  }

  return <>
    <Typography variant="h5">Subscription</Typography>
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Updated</TableCell>
            <TableCell>Expires</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Product SKU</TableCell>
            <TableCell>Auto Renew</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscription === undefined
            ?
            <InsertSkeleton />
            :
            renderSubscriptionRow()
          }
        </TableBody>
      </Table>
    </TableContainer>
  </>;
}

