import { Link as GatsbyLink } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";

type Props = {
    variant?: Variant,
    children: any,
    style?: React.CSSProperties
} & ({
    onClick: () => void
} | {
    href: string|undefined
});

export default function InternalLink(props: Props) {
    return <GatsbyLink 
        {...props}
    >
        {props.children}
    </GatsbyLink>;
}