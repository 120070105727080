import { adminClient } from "@api/admin/AdminClient";
import { withAdminMenuLayout } from "@components/admin/Decorators";
import NotFoundComponent from "@components/admin/NotFoundComponent";
import UserDetailsComponent from "@components/admin/users/details/UserDetailsComponent";
import { queryApi } from "@hooks/api";
import { useQueryParameters } from "@hooks/navigation";
import { CircularProgress, NoSsr } from "@material-ui/core";
import { navigateTo404 } from "@utils/navigation";

interface Props {
    id: string;
}

export default withAdminMenuLayout(function UserDetailsPageComponent() {
    const props = useQueryParameters<Props>();
    if (!props.id) {
        return navigateTo404();
    }

    return <DetailsPage id={props.id} />
});

function DetailsPage(props: {id: string}) : JSX.Element {
    const [user] = queryApi(
        async (options) => await adminClient().adminUserDetailsJsonGet(props.id, options),
        [props.id]);
    if (user === undefined) {
        return <NoSsr><CircularProgress /></NoSsr>;
    }

    if (user === null)
        return <NotFoundComponent message="User not found" backUrl="/admin/users/" />

    return <UserDetailsComponent user={user} />
}
