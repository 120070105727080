import { adminClient } from "@api/admin/AdminClient";
import NotFoundComponent from "@components/admin/NotFoundComponent";
import PageTitle from "@components/admin/PageTitle";
import QrCode from "@components/admin/QrCode";
import UserProfileDropDownActionsComponent from "@components/admin/users/details/actions/UserProfileDropDownActionsComponent";
import InternalLink from "@components/InternalLink";
import WebpImage from "@components/WebpImage";
import { queryApi } from "@hooks/api";
import {
	Box,
	ButtonGroup,
	Card,
	CardContent,
	Chip,
	Divider,
	FormControlLabel,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Theme,
	Typography
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import BlockIcon from "@material-ui/icons/Block";
import CheckIcon from "@material-ui/icons/Check";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import Skeleton from "@material-ui/lab/Skeleton";
import { AdminContactPhoneModel, AdminUserResponse, Device, DeviceType } from "@reshopper/admin-client";
import { addPlusIfPositiveNumber, formatNumber, formatUserAddress } from "@utils/formatting";
import { toDanishDateFormat } from "@utils/miscellaneous";
import { navigateToExternalUrl } from "@utils/navigation";
import { useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		TableCell: {
			borderBottom: "none",
			paddingTop: "4px",
			paddingBottom: "4px",
		},
		TableCellBold: {
			borderBottom: "none",
			paddingTop: "4px",
			paddingBottom: "4px",
			fontWeight: "bold",
		},
		TopCell: {
			paddingTop: "0",
			borderBottom: "none",
		},
		TopCellBold: {
			paddingTop: "0",
			borderBottom: "none",
			fontWeight: "bold",
		},
		SmallIcon: {
			verticalAlign: "middle",
			paddingBottom: "4px",
		},
		TypographyFontSize: {
			fontSize: "0.875rem",
		},
		button: {
			height: 20,
			marginLeft: 10
		}
	}),
);

interface TableForUserProfilePageProps {
	user: AdminUserResponse;
}

function TableForUserProfilePage(props: TableForUserProfilePageProps): JSX.Element {
	const classes = useStyles();

	const [revenue] = queryApi(
		async (options) => await adminClient().adminUserReshopperRevenueForUserGet(props.user.id, options),
		[props.user.id]);

	const [ratingStatistics] = queryApi(
		async (options) => await adminClient().adminRatingsUserRatingStatisticsForReceiverGet(props.user.id, options),
		[props.user.id]);

	function renderVerifiedPhoneIcon(verified: boolean | undefined) {
		return verified ?
			<CheckIcon className={classes.SmallIcon} /> :
			<NotInterestedIcon className={classes.SmallIcon} />
	}

	function renderTableDescriptionForUserProfilePageRow(description: string): JSX.Element {
		return <TableCell className={classes.TableCellBold}>
			{description}
		</TableCell>
	}

	function getPhoneInfo(phone: Partial<AdminContactPhoneModel> | undefined): JSX.Element {
		if (!phone?.phoneNumber)
			return <Typography className={classes.TypographyFontSize}>No phone number</Typography>

		return <>
			{phone.countryCode}
			{phone.phoneNumber}
			{renderVerifiedPhoneIcon(phone.verified)}
		</>
	}

	function getDeviceTypeFromDeviceType(type: DeviceType | undefined): string {
		if (!type)
			return "Unknown";

		return type === "android" ? "Android" : "iOS"
	}

	function renderLastestVersionAndTypeFromDevice(devices: Device[]) {
		if (devices.length === 0)
			return null;

		return getDeviceTypeFromDeviceType(devices[0].deviceType) + " " + devices[0].clientVersion;
	}

	function GetAddressForUser(): JSX.Element {
		if (props.user.settings?.addresses?.length) {
			return <>
				<Typography className={classes.TypographyFontSize}>
					{formatUserAddress(props.user.settings.addresses[0])}
				</Typography>
			</>
		}
		return <>
			No Address
		</>
	}

	const getTotalReshopperRevenueForUser = (): number => {
		if (!revenue) {
			return 0;
		}
		return (revenue?.feesRevenueInHundreds + revenue?.creditsRevenueInHundreds + revenue?.subscriptionsRevenueInHundreds) / 100;
	}

	const renderRevenueRow = (): JSX.Element => {
		return <TableRow>
			{renderTableDescriptionForUserProfilePageRow("Revenue (incl. VAT)")}
			<TableCell className={classes.TableCell}>
				{revenue ?
					getReshopperRevenueString() :
					"No revenue"}
			</TableCell>
		</TableRow>;
	}

	const getReshopperRevenueString = (): string => {
		if (!revenue) {
			return "";
		}
		return (
			formatNumber(getTotalReshopperRevenueForUser()) +
			" DKK (Plus " +
			formatNumber(revenue?.subscriptionsRevenueInHundreds / 100) +
			" DKK + Fee " +
			formatNumber(revenue?.feesRevenueInHundreds / 100) +
			" DKK + Credits " +
			formatNumber(revenue?.creditsRevenueInHundreds / 100) +
			" DKK)"
		);
	}

	const user = props.user;
	return <>
		<Table size="small" style={{
			width: "auto"
		}}>
			<TableBody>
				<TableRow>
					<TableCell className={classes.TopCellBold}>
						{"Address"}
					</TableCell>
					<TableCell className={classes.TopCell}>
						<GetAddressForUser />
					</TableCell>
				</TableRow>
				<TableRow>
					{renderTableDescriptionForUserProfilePageRow("Contact")}
					<TableCell className={classes.TableCell}>
						<InternalLink onClick={() => navigateToExternalUrl("https://secure.helpscout.net/mailbox/67e8539c8d2a1572/new-ticket/?email=" + props.user.email, true)}>
							{user.email}
						</InternalLink>
						{user.emailVerified ?
							<CheckIcon className={classes.SmallIcon} /> :
							<BlockIcon className={classes.SmallIcon} />}
						{getPhoneInfo(user.settings?.contactPhone)}
					</TableCell>
				</TableRow>
				<TableRow>
					{renderTableDescriptionForUserProfilePageRow("Last seen")}
					<TableCell className={classes.TableCell}>
						{toDanishDateFormat(user.lastSessionUtc) + " / "}
						{renderLastestVersionAndTypeFromDevice(user.devices) + " / "}
						{"created " + toDanishDateFormat(user.createdUtc)}</TableCell>
				</TableRow>
				<TableRow>
					{renderTableDescriptionForUserProfilePageRow("Plus")}
					<TableCell className={classes.TableCell}>
						{renderSubscriptionChips(props)}
					</TableCell>
				</TableRow>
				<TableRow>
					{renderTableDescriptionForUserProfilePageRow("Credits")}
					<TableCell className={classes.TableCell}> {user.creditAmount} </TableCell>
				</TableRow>
				{renderRevenueRow()}
				<TableRow>
					{renderTableDescriptionForUserProfilePageRow("Rating")}
					<TableCell className={classes.TableCell}>
						{!ratingStatistics ?
							"No ratings" :
							(addPlusIfPositiveNumber(ratingStatistics?.ratingsSum) + " total (" +
								ratingStatistics?.ratingsCount + " reviews) / " +
								addPlusIfPositiveNumber(parseFloat(ratingStatistics?.ratingsAverage?.toFixed(2))) + " avg")} </TableCell>
				</TableRow>
				<TableRow>
					{renderTableDescriptionForUserProfilePageRow("Median First Response Time")}
					<TableCell className={classes.TableCell}>
						{!!user.stats.medianSellerFirstResponseTimeInMinutes ? user.stats.medianSellerFirstResponseTimeInMinutes + " min" : "No responses"}
					</TableCell>
				</TableRow>
				<TableRow>
					{renderTableDescriptionForUserProfilePageRow("External Service Id")}
					<TableCell className={classes.TableCell}>{user.externalServiceUserId}</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	</>
}

function toPascalCase(props: TableForUserProfilePageProps) {
	const plusType = props.user.plusTypeEnum?.toString();
	if (!plusType)
		return plusType;

	return plusType[0].toUpperCase() + plusType.substr(1).toLowerCase();
}

function renderPremiumChip(props: TableForUserProfilePageProps) {
	if (props.user.plusUser) {
		return <Chip
			size="small"
			label={props.user.plusUser
				? toPascalCase(props)
				: "No plustype"}
			variant="outlined"
			color="primary" />
	}
	return <Chip
		size="small"
		label="Not Active"
		variant="outlined" />
}

function renderTrialChip(props: TableForUserProfilePageProps) {
	if (props.user.isPlusTrial) {
		return <Chip
			size="small"
			label="Trial"
			variant="outlined" />
	}
}

function renderExpirationDate(props: TableForUserProfilePageProps) {
	if (props.user.subscriptionExpiresUtc != null) {
		return "  Expires: " + toDanishDateFormat(props.user.subscriptionExpiresUtc);
	}
	return "  No Expiration Date"

}

function renderSubscriptionChips(props: TableForUserProfilePageProps) {
	return <>
		{renderPremiumChip(props)}
		{renderTrialChip(props)}
		{renderExpirationDate(props)}
	</>
}

function InsertSkeleton(): JSX.Element {

	function tableSkeleton(): JSX.Element[] {
		let elements = []

		for (let index = 0; index < 8; index++) {
			elements.push(
				<TableRow key={index}>
					<Skeleton
						height={28}
						width={547}
						component="td" />
				</TableRow>
			)
		}
		return elements;
	}

	return <>
		<Skeleton height={48} />
		<Divider />
		<Box display="flex" flexDirection="row">
			<Box flexGrow={1}>
				<Skeleton variant="rect" height={200} width={200} />
			</Box>

			<Table size="small">
				<TableBody>
					{tableSkeleton()}
				</TableBody>
			</Table>
		</Box>
	</>
}

interface Props {
	userId: string;
}

function renderBlocked(blocked: boolean) {
	if (blocked)
		return <BlockIcon color="error" />
}

function renderShadowblocked(shadowblocked: boolean) {
	if (shadowblocked) {
		return <PersonOutlineIcon />
	}
}

export default function UserProfileComponent(props: Props) {
	const [shouldImpersonate, setShouldImpersonate] = useState(false);

	const [user] = queryApi(
		async (options) => await adminClient().adminUserDetailsJsonGet(props.userId, options),
		[props.userId]);

	if (user === undefined)
		return <InsertSkeleton />

	if (user === null) {
		return <NotFoundComponent message="User not found" backUrl="/admin/users" />;
	}

	return <>
		<PageTitle title={<>{user.firstName} {user.lastName} {renderBlocked(!!user.blockedUtc)} {renderShadowblocked(!!user.shadowblockedAtUtc)}</>}>
			<ButtonGroup>
				<UserProfileDropDownActionsComponent user={user} />
			</ButtonGroup>
		</PageTitle>
		<Card>
			<CardContent>
				<Box display="flex">
					<WebpImage
						images={user.profileImages}
						size={250} />
					<TableForUserProfilePage user={user} />
					<Box flexGrow={1} />
					<Box>
						<FormControlLabel
							control={<Switch checked={shouldImpersonate} onChange={e => setShouldImpersonate(e.target.checked)} value="true" />}
							label="Impersonate"
							style={{
								display: 'block',
								textAlign: 'right'
							}}
						/>
						<QrCode
							text={`reshopper%3A%2F%2Fu%2F${props.userId}${shouldImpersonate ? "/impersonate" : ""}`}
							link={`https://www.reshopper.${user.country}/u/${props.userId}`}
							size={155} />
					</Box>
				</Box>
				{user.itemsDeactivatedUntilUtc ?
					<Typography style={{ color: "blue" }}>
						- On vacation until {toDanishDateFormat(user.itemsDeactivatedUntilUtc, "PP")}
					</Typography> :
					<></>}
				{user.monitored ?
					<Typography style={{ color: "red" }}>
						Monitored
					</Typography> :
					<></>}
			</CardContent>
		</Card>
	</>
}
