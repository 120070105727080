import { adminClient } from "@api/admin/AdminClient";
import { queryApi } from "@hooks/api";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, ListItemIcon, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ControlPointDuplicateIcon from "@material-ui/icons/ControlPointDuplicate";
import { IPlusType } from "@reshopper/admin-client";
import { performAction } from "@utils/actions";
import { toPascalCase } from "@utils/strings";
import { useState } from "react";

export default function ChangeSubscriptionPlusTypeDropdownMenuItem(
  props: {
    id: string,
    onClose: () => void
  }
): JSX.Element {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [currentlySelectedPlusTypeIndex, setCurrentlySelectedPlusTypeIndex] = useState<number>(0);

  const [plusTypes] = queryApi(
    async (options) => await adminClient().adminUserSubscriptionPlusTypesGet(options),
    []);

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }));

  const classes = useStyles();

  function handleOpen() {
    setConfirmationDialogOpen(true);
    props.onClose();
  }

  function ChangePlusTypeDialogComponent() {
    const [currentlySelectedMonths, setCurrentlySelectedMonths] = useState<number | undefined>(1);

    function handlePlusTypeChange(event: React.ChangeEvent<{ value: unknown }>) {
      if (!plusTypes)
        throw new Error("Expected plus types to be initialized.");

      let plusTypeIndex = plusTypes.indexOf(event.target.value as IPlusType);
      setCurrentlySelectedPlusTypeIndex(plusTypeIndex);
    }

    async function changeSubscriptionPlusType() {
      if (!plusTypes || !plusTypes[currentlySelectedPlusTypeIndex])
        throw new Error("Expected selected plus type.");

      setConfirmationDialogOpen(false);

      let months = isPremiumPlusTypeSelected() ?
        currentlySelectedMonths :
        undefined;
      await performAction(
        async () =>
          await adminClient().adminUserSubscriptionChangeSubscriptionPlusTypePut(props.id, {
            plusType: plusTypes[currentlySelectedPlusTypeIndex].plusType,
            months: months
          }),
        "Failed to change plustype for subscription",
        false)
      window.location.reload();
    }

    function isPremiumPlusTypeSelected() {
      if (!plusTypes)
        return null;

      return plusTypes[currentlySelectedPlusTypeIndex]?.plusType === "premium";
    }

    if (!plusTypes)
      return null;

    return <Dialog
      open={confirmationDialogOpen}
      onClose={() => setCurrentlySelectedMonths(undefined)}
      transitionDuration={0}
      keepMounted
    >
      <DialogTitle>
        Change Plus Type
      </DialogTitle>
      <DialogContent>
        <FormControl className={classes.formControl}>
          <InputLabel>
            Plustypes
          </InputLabel>
          {plusTypes.length !== 0 && <Select
            native={false}
            defaultValue={plusTypes[currentlySelectedPlusTypeIndex]}
            value={plusTypes[currentlySelectedPlusTypeIndex]}
            onChange={handlePlusTypeChange}
            fullWidth={false}
            labelWidth={20}
          >
            {plusTypes.map(plusType =>
              plusType.plusType !== "business" &&
              plusType.plusType !== "retail" &&
              <MenuItem
                value={plusType as any}
                key={plusType.plusType}>
                {toPascalCase(plusType.plusType)}
              </MenuItem>)
            }
          </Select>}
          {isPremiumPlusTypeSelected() &&
            <FormControl className={classes.formControl}>
              <InputLabel>
                Months
              </InputLabel>
              <Select
                native={false}
                value={currentlySelectedMonths}
                onChange={event => setCurrentlySelectedMonths(event.target.value as number)}
                fullWidth={true}
                labelWidth={20}
              >
                <MenuItem
                  value={1}
                  key={1}>
                  1
                </MenuItem>
                <MenuItem
                  value={6}
                  key={6}>
                  6
                </MenuItem>
                <MenuItem
                  value={12}
                  key={12}>
                  12
                </MenuItem>
              </Select>
            </FormControl>
          }
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setConfirmationDialogOpen(false)}
          variant="outlined"
          color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => changeSubscriptionPlusType()}
          variant="contained"
          color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  }

  if (!plusTypes || plusTypes.length === 0) {
    return <></>
  }

  return <>
    <MenuItem button
      onClick={handleOpen}
    >
      <ListItemIcon>
        <ControlPointDuplicateIcon />
      </ListItemIcon>
      Change Plus Type
    </MenuItem>
    <ChangePlusTypeDialogComponent />
  </>
}