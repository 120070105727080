import StackBarChartComponent from "@components/admin/StackBarChartComponent";
import { GraphPoint } from "@components/admin/stats/StatsHelper";
import { Box, Container, Typography } from "@material-ui/core";
import { BarLegendProps } from "@nivo/bar";

export default function StackBarSectionComponent(props:
	{
		stats: GraphPoint[],
		displayTicks: boolean,
		title?: string,
		labelFontSize?: number,
		tickFontSize?: number,
		height?: number,
		marginTop?: number,
		marginBottom?: number,
		marginLeft?: number,
		barBackgroundColors?: any[],
		textColor?: string,
		backgroundColor?: string,
		legends?: BarLegendProps[] | undefined,
		xAxisTickFormat?: string
	}) {

		const generateResponsiveBarColors = () => {
			if (!props.barBackgroundColors) {
				return { scheme: 'category10' };
			}
			if(!props.barBackgroundColors[1]){
				return props.barBackgroundColors[0];
			}
			return props.barBackgroundColors;
		}

	return <Box m={0}>
		{!!props.title && <Typography variant="h6" style={{
			marginTop: 12
		}}>
			{props.title}
		</Typography>}
		<Container
			maxWidth={false}
			style={{
				height: props.height ?? 500,
				width: "100%",
				padding: 0,
				marginTop: -8
			}}>
			<StackBarChartComponent
				data={props.stats}
				displayTicks={props.displayTicks}
				labelFontSize={props.labelFontSize}
				tickFontSize={props.tickFontSize}
				marginTop={props.marginTop}
				marginLeft={props.marginLeft}
				marginBottom={props.marginBottom}
				barBackgroundColors={generateResponsiveBarColors()}
				textColor={props.textColor}
				backgroundColor={props.backgroundColor}
				legends={props.legends}
				xAxisTickFormat={props.xAxisTickFormat}
				/>
		</Container>
	</Box>;
}