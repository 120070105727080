import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function RemovePlusFromUserDropdownMenuItemComponent(props: {id: string, onClose: () => void}): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleOpen() {
    setDialogOpen(true);
    props.onClose();
  }

  return <>
    <MenuItem button
      onClick={handleOpen}
    >
      <ListItemIcon>
        <MoneyOffIcon />
      </ListItemIcon>
      Remove plus
    </MenuItem>
    <ConfirmationDialogComponent
          isDialogOpen={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onConfirm={() => performAction(
            () => adminClient().adminUserSubscriptionRemoveSubscriptionDelete(props.id), 
            "Failed to remove plus from user")}
          title="Confirm removal of plus"
          description="Are you sure you want to remove plus from this user?"
        />
  </>
}
