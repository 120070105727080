import { adminClient } from "@api/admin/AdminClient";
import BoolNewLabelDialogComponent from "@components/admin/BookNewLabelDialog";
import InformationDialogComponent from "@components/admin/InformationDialogComponent";
import { ListItemIcon, MenuItem, Typography } from "@material-ui/core";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import { LabelBookingResult } from "@reshopper/admin-client";
import { ShippingProviderType } from "@reshopper/web-client";
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function BookShippingLabelDropdownMenuItemComponent(props: {id: string, onClose: () => void}): JSX.Element {
  const [bookingResult, setBookingResult] = useState<LabelBookingResult|null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function handleOpen() {
    props.onClose();
    setIsDialogOpen(true);
  }

  function action(userId: string, providerType: ShippingProviderType, pickUpPoint: string | undefined, weightUpTil: number) : Promise<void> {
    return performAction(async () => 
          setBookingResult(await adminClient().adminUserBookShippingLabelPost(userId, {
          shippingProviderType: providerType,
          pickupPointId: pickUpPoint,
          weight: weightUpTil
        })),
        "Failed to book shipping label",
        false
    ); 
  }

  return <>
    <MenuItem button
      onClick={handleOpen}
    >
      <ListItemIcon>
        <FiberNewIcon />
      </ListItemIcon>
      Book new label
    </MenuItem>
    <BoolNewLabelDialogComponent 
      performAction={action}
      objectId={props.id}
      isOpen={isDialogOpen}
      setIsOpen={setIsDialogOpen} />
    <InformationDialogComponent
      isDialogOpen={bookingResult !== null}
      onDismiss={() => {
        setBookingResult(null);
      }}
      dialogTitle="Shipping label booked"
      dialogContent={() =>
        <>
          <Typography>Package Label: {(bookingResult?.packageLabel || "")}</Typography>
          <Typography>Package Number: {(bookingResult?.packageNumber || "")}</Typography>
          <Typography>Label: {(bookingResult?.linkToLabelAsPdf || "")}</Typography>
        </>} />
  </>
}
