import { adminClient } from "@api/admin/AdminClient";
import RemovePaymentMethodsDropdownMenuItem from "@components/admin/users/details/actions/payment/RemovePaymentMethodsDropdownMenuItem";
import ChangeSubscriptionPlusTypeDropdownMenuItem from "@components/admin/users/details/actions/plus/ChangeSubscriptionPlusTypeDropdownMenuItem";
import RemovePlusFromUserDropdownMenuItem from "@components/admin/users/details/actions/plus/RemovePlusFromUserDropdownMenuItem";
import BookShippingLabelDropdownMenuItem from "@components/admin/users/details/actions/shipping/BookShippingLabelDropdownMenuItem";
import ActiveUserCountWithinAreaMenuItem from "@components/admin/users/details/actions/view/ViewActiveUserCountWithinAreaDropdownMenuItem";
import MarketingDropDownMenuItem from "@components/admin/users/details/actions/view/ViewMarketingDropdownMenuItem";
import { useAsyncEffect } from "@hooks/miscellaneous";
import {
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemText,
  Menu,
  Typography
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { AccessRole, AdminUserResponse } from "@reshopper/admin-client";
import React, { useState } from "react";

interface Props {
  user: AdminUserResponse;
}

export default function UserProfileDropDownActionsComponent(props: Props) {
  const [hasRequestPlusReceiptState, setHasRequestPlusReceiptState] = useState<
    boolean | null
  >(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isReepayCustomer, setIsReepayCustomer] = useState<boolean | null>(
    null
  );
  const [userAccessRole, setUserAccessRole] = useState<
    AccessRole | undefined
  >();

  const [selectedIndex, setSelectedIndex] = React.useState<number>();
  useAsyncEffect(async () => {
    try {
      let hasRequestPlusReceipt =
        await adminClient().adminUserSubscriptionRequestSubscriptionReceiptStateGet(
          props.user.id
        );
      setHasRequestPlusReceiptState(hasRequestPlusReceipt.result);

      let isReepayCustomerState =
        await adminClient().adminUserAccountIsReepayCustomerGet(props.user.id);
      setIsReepayCustomer(isReepayCustomerState.result);

    } catch (error: any) {
      console.log(error.status);
    }
  }, []);

  const handleClick = (index: number) => {
    if (selectedIndex === index) {
      setSelectedIndex(undefined);
    } else {
      setSelectedIndex(index);
    }
  };
  function onClose() {
    setAnchorEl(null);
  }

  function renderRequestReepayReceipt() {
    if (!hasRequestPlusReceiptState) return;

    return (
      <Typography variant="subtitle2" color="primary">
        Plus reciept requested
      </Typography>
    );
  }

  function handleOpening(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <>
      {renderRequestReepayReceipt()}
      <Button onClick={handleOpening}>Actions</Button>
      <Menu
        keepMounted
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <List style={{ width: "330px" }} disablePadding>
          <ListItem
            button={true}
            key={6}
            onClick={() => {
              handleClick(6);
            }}
          >
            <ListItemText primary="Payment" />
            {6 === selectedIndex ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={6 === selectedIndex} timeout="auto" unmountOnExit>
            {isReepayCustomer && (
              <RemovePaymentMethodsDropdownMenuItem
                id={props.user.id}
                onClose={onClose}
              />
            )}
            <Divider />
          </Collapse>
          <ListItem
            button={true}
            key={7}
            onClick={() => {
              handleClick(7);
            }}
          >
            <ListItemText primary="Plus" />
            {7 === selectedIndex ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={7 === selectedIndex} timeout="auto" unmountOnExit>
            <ChangeSubscriptionPlusTypeDropdownMenuItem
              id={props.user.id}
              onClose={onClose}
            />
            {props.user.plusUser ? (
              <RemovePlusFromUserDropdownMenuItem
                id={props.user.id}
                onClose={onClose}
              />
            ) : (
              <></>
            )}
            <Divider />
          </Collapse>

          <ListItem
            button={true}
            key={8}
            onClick={() => {
              handleClick(8);
            }}
          >
            <ListItemText primary="Shipping" />
            {8 === selectedIndex ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={8 === selectedIndex} timeout="auto" unmountOnExit>
            <BookShippingLabelDropdownMenuItem
              id={props.user.id}
              onClose={onClose}
            />
            <Divider />
          </Collapse>

          <ListItem
            button={true}
            key={9}
            onClick={() => {
              handleClick(9);
            }}
          >
            <ListItemText primary="View" />
            {9 === selectedIndex ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={9 === selectedIndex} timeout="auto" unmountOnExit>

            <MarketingDropDownMenuItem
              userId={props.user.id}
              onClose={onClose}
            />
            {props.user.settings?.addresses?.length && (
              <ActiveUserCountWithinAreaMenuItem
                address={props.user.settings.addresses[0]}
                onClose={onClose}
              />
            )}
            <Divider />
          </Collapse>

        </List>
      </Menu>
    </>
  );
}
