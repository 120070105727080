import { ListItemIcon, MenuItem } from "@material-ui/core";
import SettingsInputAntennaIcon from "@material-ui/icons/SettingsInputAntenna";

export default function MarketingDropDownMenuItem(props: { userId: string, onClose: () => void }): JSX.Element {

    function handleOpen() {
        props.onClose();
        action();
    }

    async function action() {
        window.location.href = "/admin/marketing/?id=" + encodeURIComponent(props.userId);
    }

    return <>
        <MenuItem button
            onClick={handleOpen}
        >
            <ListItemIcon>
                <SettingsInputAntennaIcon />
            </ListItemIcon>
                 View Marketing
              </MenuItem>
    </>
}