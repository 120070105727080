import ActiveUserCountWithinAreaDialogComponent from "@components/admin/users/ActiveUserCountWithinAreaDialogComponent";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import { AdminProfileSettingsAddressModel } from "@reshopper/admin-client";
import { useState } from "react";

export default function ActiveUserCountWithinAreaMenuItem(
    props: {
        address?: AdminProfileSettingsAddressModel,
        onClose: () => void
    }
): JSX.Element {
    const [dialogOpen, setDialogOpen] = useState(false);

    function handleOpen() {
        setDialogOpen(true);
        props.onClose();
    }

    return <>
        <MenuItem button
            onClick={handleOpen}
        >
            <ListItemIcon>
                <PersonPinCircleIcon />
            </ListItemIcon>
           View Active user count within area
    </MenuItem>
        <ActiveUserCountWithinAreaDialogComponent
            address={props.address}
            confirmationDialogOpen={dialogOpen}
            onClose={() => setDialogOpen(false)}
        />
    </>
}