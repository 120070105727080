import { adminClient } from "@api/admin/AdminClient";
import DateRangePickerComponent from "@components/admin/DateRangePickerComponent";
import StackBarSectionComponent from "@components/admin/stats/StackBarSectionComponent";
import { GraphPoint } from "@components/admin/stats/StatsHelper";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { AdminStatsDailySessionsGetPostStatResponse } from "@reshopper/admin-client";
import { formatNumber } from "@utils/formatting";
import { endOfDay, setHours, startOfDay, subDays } from "date-fns";
import { useState } from "react";

export default function UserDailySessionStatsComponent(props: {
	userId: string;
}) {
	const now = new Date();
	const [fromDate, setFromDate] = useState<Date>(subDays(startOfDay(now), 7));
	const [toDate, setToDate] = useState<Date>(endOfDay(now));
	const [graphPoints, setGraphPoints] = useState<GraphPoint[]>(new Array<GraphPoint>());
	const [userDailySessionStats, setUserDailySessionStats] = useState<AdminStatsDailySessionsGetPostStatResponse[] | null>(null);

	function onSubmit(fromDate: Date | null, toDate: Date | null) {
		if (!fromDate || !toDate) {
			return;
		}
		setFromDate(fromDate);
		setToDate(toDate);
		fetchUserDailySessionStatsAndMapToGraphPoints(fromDate, toDate);
	}

	async function fetchUserDailySessionStatsAndMapToGraphPoints(fromDate: Date, toDate: Date) {
		let userDailySessionStats = await adminClient().adminStatsDailySessionsGetPost(
			props.userId,
			{
				body: {
					userId: props.userId,
					fromUtc: setHours(startOfDay(fromDate), 12),
					toUtc: setHours(startOfDay(toDate), 12)
				}
			});
		setUserDailySessionStats(userDailySessionStats.stats);
		let graphStats = generateGraphPoints(userDailySessionStats.stats);
		setGraphPoints(graphStats);
	}

	function generateGraphPoints(userDailySessionStats: AdminStatsDailySessionsGetPostStatResponse[]) {
		return userDailySessionStats?.map(stat => { return { 
			x: stat.timeUtc, 
			y1: stat.count 
		} as GraphPoint });
	}

	function calculateUserDailySessionsTotalCount() {
		if (!userDailySessionStats) {
			return 0;
		}
		return userDailySessionStats.reduce((sum, current) => sum + current.count, 0);
	}

	return <>
		<Box
			style={{
				marginTop: 32
			}}
		>
			<Card>
				<CardContent>
					<Typography variant="h4">User Sessions</Typography>
					<Box
						display="flex"
						flexDirection="reverse-row"
						alignItems="center"
						flexWrap="nowrap"
						justifyContent="flex-end"
						paddingBottom={2}
					>
						<DateRangePickerComponent
							onSubmit={onSubmit}
							renderTimeOption={false}
							renderDateRanges={true}
							disableFutureDates={true}
							disablePastDates={false}
							submitOnLoad={false}
							variant="outlined"
							defaultFromDate={fromDate}
							defaultToDate={toDate}
							/>
					</Box>
					{graphPoints.length === 0 ? <></> :
						<>
							<StackBarSectionComponent
								stats={graphPoints}
								displayTicks={true}
								title="User sessions per day"
							/>
							<Box
								display="flex"
								flexDirection="reverse-row"
								alignItems="center"
								flexWrap="nowrap"
								justifyContent="flex-end"
							>
								<Box>
									<Typography variant="h5">
										Total user sessions in period: {formatNumber(calculateUserDailySessionsTotalCount())}
									</Typography>
								</Box>
							</Box>
						</>}
				</CardContent>
			</Card>
		</Box>
	</>

};